<template>
  <div class="search">
    <div class="search-container">
      <div class="search-bread">
        <span style="cursor: pointer" @click="$router.push('/')">首页</span>
        <span style="margin-left: 0.1rem; margin-right: 0.1rem;"> / </span>
        <span style="color: #761f1e;">搜索</span>
      </div>
      <div class="search-header">
        <div class="search-header-input">
          <!-- <div
            class="search-header-input-select"
            @click="showSlideDown = !showSlideDown"
          >
            <div class="search-header-input-select-box">
              <span>{{ selectedName }}</span>
              <img
                src="https://image.bookgo.com.cn/webculture/jm/search/search-index-slideDown.png"
                alt=""
              />
            </div>
          </div> -->
          <img
            src="https://image.bookgo.com.cn/webculture/jm/search/search-index-glass.png"
            alt=""
            class="search-header-input-select-glassPic"
          />
          <input
            type="text"
            v-model="keyword"
            @keyup.enter="getList"
            placeholder="输入您感兴趣的内容"
          />
          <div class="search-header-input-button" @click="getList">搜索</div>
          <!-- <ul class="search-header-input-selection" v-if="showSlideDown">
            <li
              v-for="(item, i) in typeList"
              :key="item.id"
              :class="{ activeSearch: current == i }"
              @mouseover="current = i"
              @click="handleToggleType(i, item)"
            >
              {{ item.name }}
            </li>
          </ul> -->
        </div>
        <div class="search-header-count">
          共查找到<i class="search-result-count">{{ count }}</i
          >条结果
        </div>
      </div>
      <div class="search-result-main" v-loading="pageLoading">
        <img
          src="https://image.bookgo.com.cn/webculture/jm/border-top.png"
          alt=""
          class="border-top"
        />
        <div class="search-result-main-noContent" v-if="count == 0">
          <div class="search-result-main-noContent-img">
            <img
              src="https://image.bookgo.com.cn/webculture/jm/search/common-noContent.png"
              alt=""
            />
          </div>
          <p>暂无内容，请搜索其它内容试试吧！</p>
        </div>
        <ul v-else>
          <li v-for="item in resultList" :key="item.id" @click="goResult(item)">
            <p>
              <span v-html="item.title_highlights"></span>
            </p>
            <em class="line"></em>
          </li>
        </ul>
        <img
          src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
          alt=""
          class="border-bottom"
        />
      </div>

      <!-- 分页组件 -->
      <Pagination
        :total="count"
        :page.sync="page"
        :limit.sync="limit"
        @pagination="getList"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination/index.vue";
import { infoList as cmsInfo, navinfo } from "@/api/header/header";
// import axios from "axios";
import { search } from "../../api/search/search";
import { getpid } from "../../api/article";
import { mapState } from "vuex";
export default {
  data() {
    return {
      current: 0,
      count: 0,
      page: 1,
      limit: 10,
      pageLoading: false,
      typeList: [
        { id: 0, name: "全部" },
        { id: 1, name: "群文活动" },
        { id: 2, name: "资讯动态" },
        { id: 3, name: "艺术品鉴" },
        { id: 4, name: "艺术研究" },
        { id: 5, name: "艺术培训" },
        { id: 6, name: "非遗保护" },
      ],
      type: "article,active,art_training,resource",
      keyword: "",
      resultList: [
        // {
        //   type_name: "活动",
        //   title_highlights: "活动名",
        // },
      ],
      selectedName: "全部",
      searchTypeText: `{"active":"群文活动","article":"资讯动态,艺术品鉴,艺术研究","art_training":"艺术培训","resource":"非遗保护"}`,
      cateId: "",
      showSlideDown: false, // 是否显示选择下拉框
      newsCateId: "",
      artCateId: "",
      artReCateId: "",
    };
  },
  created() {
    this.keyword = this.$attrs.searchText;
  },
  mounted() {
    this.getList();
  },
  components: {
    Pagination,
  },
  computed: { ...mapState(["info"]) },
  watch: {},
  methods: {
    // getFatherId(id) {
    //   getpid({ id: id }).then((res) => {
    //     console.log(res);
    //     if (res.code == 0) {
    //       this.artCateId = res.data.id;
    //     }
    //   });
    // },
    // handleToggleType(i, item) {
    //   this.page = 1;
    //   this.current = i;
    //   this.selectedName = item.name;
    //   this.showSlideDown = false;
    //   if (i == 0 && this.keyword !== "") {
    //     this.type = "article,active,art_training,resource";
    //     this.searchTypeText = `{"active":"群文活动","article":"资讯动态,艺术品鉴,艺术研究","art_training":"艺术培训","resource":"非遗保护"}`;
    //   } else if (i == 1 && this.keyword !== "") {
    //     this.type = "active";
    //     this.searchTypeText = `{"active":"群文活动"}`;
    //   } else if (i == 2 && this.keyword !== "") {
    //     this.type = "article";
    //     this.searchTypeText = `{"article":"资讯动态"}`;
    //   } else if (i == 3 && this.keyword !== "") {
    //     this.type = "article";
    //     this.searchTypeText = `{"article":"艺术品鉴"}`;
    //   } else if (i == 4 && this.keyword !== "") {
    //     this.type = "article";
    //     this.searchTypeText = `{"article":"艺术研究"}`;
    //   } else if (i == 5 && this.keyword !== "") {
    //     this.type = "art_training";
    //     this.searchTypeText = `{"art_training":"艺术培训"}`;
    //   } else if (i == 6 && this.keyword !== "") {
    //     this.type = "resource";
    //     this.searchTypeText = `{"resource":"非遗保护"}`;
    //   }
    // },
    goResult(item) {
      console.log(item);
      if(item.is_link==1){
        if(item.jump_url){
          window.open(item.jump_url, "_blank");
        }
      }else{
        navinfo({ column_id: item.column_id }).then((res) => {
        if (res.code == 0) {
          var content_id = res.data.contesnt_id;
          var tpl_code = res.data.tpl_code;
          var id = "";
          var cid = "";
          if (res.data.id) {
            if (res.data.pid == 0) {
              id = res.data.id;
            } else {
              id = res.data.pid;
              cid = res.data.id;
            }
            var column_id = res.data.column_id;
            this.$router.push(
              `/${tpl_code}/detail?tid=${item.id}&id=${id}&cid=${cid}&column_id=${column_id}`
            );
          }else {
          this.$router.push(`/${tpl_code}/detail?tid=${item.id}`);
        } 
        }
      });
      }
    },
    getList() {
      search({
        keyword: this.keyword,
        aid: process.env.VUE_APP_AID,
        search_type: this.type,
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        this.resultList = res.data.rows;

        res.data.rows.forEach((item) => {
          for (let key in item) {
            if (key === "title") {
              let replceReg = new RegExp(this.keyword, "ig");
              let replaceString =
                '<span class="highlights-text">' + this.keyword + "</span>";
              item[key + "_highlights"] = item[key].replace(
                replceReg,
                replaceString
              );
            }
          }
          return item;
        });
        this.pageLoading = false;
        this.count = res.data.total ? res.data.total : 0;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/search.scss";
</style>
